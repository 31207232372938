export const theme = [
  {
    background: "#0a192f",
    text: "#ccd6f6",
    shine: "aqua",
  },

  {
    background: "#a6d1c9",
    text: "#e83c38",
    shine: "blue",
  },
  {
    background: "#282b29",
    text: "#a6d1c9",
    shine: "aqua",
  },
  {
    background: "#f4c4c4",
    text: "#282b29",
    shine: "red",
  },
  {
    background: "#00a19a",
    text: "#ebeded",
    shine: "yellow",
  },
  {
    background: "#ebeded",
    text: "#e83c38",
    shine: "orange",
  },
  {
    background: "#01fff4",
    text: "#ff1178",
    shine: "#74ee15",
  },
  {
    background: "black",
    text: "green",
    shine: "red",
  },
  {
    background: "#446ccf",
    text: "#fff",
    shine: "cyan",
  },
  {
    background: "#D0FF4B",
    text: "#20222A",
    shine: "#0058D5",
  },
];
