import React from "react";
import "./AboutMe.css";
import { services } from "../../assets/serviceData";
import Bug from "../../assets/icons/Bug";
import API from "../../assets/icons/API";
import WebDev from "../../assets/icons/WebDev";
import Database from "../../assets/icons/Database";

function AboutMe() {
  return (
    <div className="AboutMe">
      <h1 className="main-heading">About Me</h1>
      <p>
        Creative Software Engineering graduate (
        <a
          href="https://www.zetech.ac.ke/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zetech University
        </a>
        ) and web developer from Nairobi, Kenya, working on web development,
        spring boot for backend and android ( java ) programming. I enjoy
        turning complex problems into simple, beautiful and intuitive code
        solutions.
      </p>
      <p>
        My job is to build your website so that it is functional and
        user-friendly but at the same time attractive. Moreover, I add personal
        touch to your product and make sure that is eye-catching and easy to
        use. My aim is to bring across your message and identity in the most
        creative way.
      </p>
      <h1 className="sub-heading">What I'm Doing</h1>

      <div className="services-box">
        <div className="service-item">
          <WebDev />
          <div className="service-details">
            <h1>{services[0].title}</h1>
            <p>{services[0].text}</p>
          </div>
        </div>
        <div className="service-item">
          <Bug />
          <div className="service-details">
            <h1>{services[1].title}</h1>
            <p>{services[1].text}</p>
          </div>
        </div>
      </div>

      <div className="services-box">
        <div className="service-item">
          <API />

          <div className="service-details">
            <h1>{services[2].title}</h1>
            <p>{services[2].text}</p>
          </div>
        </div>

        <div className="service-item">
          <Database />

          <div className="service-details">
            <h1>{services[3].title}</h1>
            <p>{services[3].text}</p>
          </div>
        </div>
      </div>

      <h1 className="sub-heading">Programming Skills</h1>

      <div className="skills-holder">
        <div className="html-css">
          <p>HTML/CSS (90%)</p>
          <div className="bar-empty">
            <div className="bar-filled" style={{ width: "90%" }}></div>
          </div>
        </div>
        <div className="html-css">
          <p>React JS (65%)</p>
          <div className="bar-empty">
            <div className="bar-filled" style={{ width: "65%" }}></div>
          </div>
        </div>
        <div className="html-css">
          <p>Spring Boot (80%)</p>
          <div className="bar-empty">
            <div className="bar-filled" style={{ width: "80%" }}></div>
          </div>
        </div>
        <div className="html-css">
          <p>MySQL/NoSQL (70%)</p>
          <div className="bar-empty">
            <div className="bar-filled" style={{ width: "70%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
