import img1 from "../assets/images/astronomy.jpg";
import img2 from "../assets/images/awake-ii.jpg";
import img3 from "../assets/images/the-xx.jpg";
import img4 from "../assets/images/gloom.jpg";
import img5 from "../assets/images/Metric Alphabet.jpg";
import img6 from "../assets/images/thirst.jpg";
export const projects = [
  {
    img: img1,
    id: 0,
    title: "Luxterior | Landing Page 1",
    tag: "React, CSS",
    filter: "android",
    gitLink: "/",
    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img2,
    id: 1,

    title: "Web Chat Feature",
    tag: "React, Spring Boot",
    gitLink: "/",
    filter: "android",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    id: 2,

    img: img3,
    title: "Adam Tom | Landing Page 2",

    tag: "HTML, SCSS, Javascript",
    gitLink: "/",
    filter: "website",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img4,
    id: 3,

    title: "Prevue | Movie Trailer App  ",
    tag: "API, Java, XML, Spring Boot",
    gitLink: "/",
    filter: "website",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img5,
    id: 4,

    title: "AdminIt | Admin Chat App",
    tag: "Java, XML, Spring Boot",
    gitLink: "/",
    filter: "others",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img6,
    id: 5,

    title: "FitMe | Gym Workout Tracker",
    tag: "XML, Java, Room DB",
    gitLink: "/",
    liveLink: "/",
    filter: "article",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },

  //

  {
    img: img1,
    id: 6,
    title: "Luxterior | Landing Page 1",
    tag: "React, CSS",
    filter: "android",
    gitLink: "/",
    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img2,
    id: 7,

    title: "Web Chat Feature",
    tag: "React, Spring Boot",
    gitLink: "/",
    filter: "android",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    id: 8,

    img: img3,
    title: "Adam Tom | Landing Page 2",

    tag: "HTML, SCSS, Javascript",
    gitLink: "/",
    filter: "website",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img4,
    id: 9,

    title: "Prevue | Movie Trailer App  ",
    tag: "API, Java, XML, Spring Boot",
    gitLink: "/",
    filter: "website",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img5,
    id: 10,

    title: "AdminIt | Admin Chat App",
    tag: "Java, XML, Spring Boot",
    gitLink: "/",
    filter: "others",

    liveLink: "/",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
  {
    img: img6,
    id: 11,

    title: "FitMe | Gym Workout Tracker",
    tag: "XML, Java, Room DB",
    gitLink: "/",
    liveLink: "/",
    filter: "article",
    desc: "Creative Software Engineering graduate and web developer from Nairobi, Kenya, working on web development, spring boot for backend and android ( java ) programming. I enjoy turning complex problems into simple, beautiful and intuitive code solutions.",
  },
];
