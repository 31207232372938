import React from "react";
import Switch from "../Custom Switch/Switch";
import "./Cookies.css";
function Cookies() {
  return (
    <div className="Cookies">
      <h1 className="main-heading">Site Permissions</h1>
      <p>
        Please choose whether this site may use cookies or related technologies
        such as web beacons, notifications and location as described below. You
        can learn more about how this site uses extra features and related
        technologies by reading below. You can choose what to allow and what not
      </p>
      <div className="skills-holder cookie">
        <h1>Location Access</h1>
        <p>
          Essential (Technical) cookies help make a website usable by enabling
          basic functions like page navigation and access to secure areas of the
          website. The website cannot function (properly) without these cookies.
        </p>
        <Switch />
      </div>
      <div className="skills-holder cookie">
        <h1>Notification Access</h1>

        <p>
          These cookies help us to understand how visitors interact with
          websites by collecting and reporting information anonymously.
        </p>
        <Switch />
      </div>
      <div className="skills-holder cookie">
        <h1>Cookies Usage</h1>

        <p>
          Marketing cookies are used to follow visitors across websites. The
          intention is to display ads that are relevant and engaging for the
          individual user and thereby more valuable for publishers and third
          party advertisers.
        </p>
        <Switch />
      </div>
      <a type="submit" className="send-message" onClick={(e) => {}}>
        Save Preferences
      </a>
    </div>
  );
}

export default Cookies;
