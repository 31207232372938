import React, { useState } from "react";
import { Link } from "react-router-dom";
import { projects } from "../../assets/projectsData";
import Project from "../Project View/Project";
import "./Portfolio.css";

function Portfolio() {
  const [projectsList, setprojectsList] = useState(projects);
  const filterProjects = (tagToUse) => {
    const newProjectList = projects.filter((project) => {
      return project.filter === tagToUse;
    });
    setprojectsList(newProjectList);
  };
  return (
    <div className="portfolio-containeer">
      <h1 className="main-heading">Portfolio</h1>
      <div className="filterTab">
        <button
          className="tab tab-act"
          onClick={() => {
            setprojectsList(projects);
          }}
        >
          All Projects
        </button>
        <button className="tab" onClick={() => filterProjects("android")}>
          Android
        </button>
        <button className="tab" onClick={() => filterProjects("website")}>
          Website
        </button>
        <button className="tab" onClick={() => filterProjects("backend")}>
          Backend
        </button>
        <button className="tab" onClick={() => filterProjects("other")}>
          Other
        </button>
      </div>

      <div className="projects-holder">
        {projectsList.map((project) => {
          return (
            <Link to={`/project/${project.id}`} component={Project}>
              <div className="project-item" key={project.index}>
                <div className="img">
                  <img src={`${project.img}`} alt="" />
                </div>
                <h1>{project.title}</h1>
                <p>{project.tag}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Portfolio;
