import React, { useState } from "react";
import "./Switch.css";
function Switch() {
  const [isOn, setisOn] = useState(false);

  function updateIsOn(e) {
    if (document.getElementById("id_input").checked) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div
      className="switch"
      onClick={(e) => {
        updateIsOn(e);
      }}
    >
      <label for="id_input">
        <input id="id_input" type="checkbox"></input>
      </label>
    </div>
  );
}

export default Switch;
