import React from "react";

function Settings() {
  return (
    <svg
      aria-label="Error sign"
      viewBox="0 0 24 24"
      role="img"
      style={{ fill: "var(--color-text)" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15v-2h2v2h-2zm0-10v6h2V7h-2z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      />
    </svg>
  );
}

export default Settings;
