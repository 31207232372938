import "./App.css";
import "../src/assets/Glitch.css";
import myImg from "../src/assets/images/myImg.jpg";

import AboutMe from "./components/About Me/AboutMe";
import ContactMe from "./components/Contact Me/ContactMe";
import Portfolio from "./components/Portfolio/Portfolio";
import Cookies from "./components/Cookies/Cookies";
import Project from "./components/Project View/Project";

import Home from "./components/Home/Home";
import ReactTooltip from "react-tooltip";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { useState } from "react";
import { theme } from "./assets/Themes";

import Settings from "./assets/icons/Settings";
import Telegram from "./assets/icons/Telegram";
import Twitter from "./assets/icons/Twitter";
import LinkedIn from "./assets/icons/LinkedIn";
import Fork from "./assets/icons/Fork";
import Menu from "./assets/icons/Menu";

function updateLog(e) {
  e.target.textContent("hello world");
}

function App() {
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  const [newTheme, setNewTheme] = useState(0);
  document.documentElement.style.setProperty(
    "--color-background",
    theme[newTheme].background
  );
  document.documentElement.style.setProperty(
    "--color-shine",
    theme[newTheme].shine
  );
  document.documentElement.style.setProperty(
    "--color-text",
    theme[newTheme].text
  );

  return (
    <Router>
      <div className="App">
        <ReactTooltip />

        <div className="top-bar">
          <a href="/home" className="logo">
            Billy K.
          </a>

          <div
            className="menu"
            onClick={() => {
              document.querySelector(".nav-wrapper").classList.toggle("open");
            }}
          >
            <Menu />
          </div>

          <div className="nav-wrapper ">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/projects">Portfolio</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="mid-bar">
          <div
            className="mid-left glitch column aqua glitch__item"
            onClick={() => {
              if (newTheme === theme.length - 1) {
                setNewTheme(0);
              } else {
                setNewTheme(newTheme + 1);
              }
            }}
          >
            <div class="glitch__layers">
              <div class="glitch__layer"></div>
              <div class="glitch__layer"></div>
              <div class="glitch__layer"></div>
            </div>
            <div className="overlay"></div>

            <img className="myImgG" alt="" src={myImg}></img>
          </div>

          <div className="mid-right">
            <Routes>
              <Route exact index element={<Home />}></Route>
              <Route exact path="/home" element={<Home />}></Route>

              <Route exact path="/project/:id" element={<Project />}></Route>
              <Route exact path="/about" element={<AboutMe />}></Route>
              <Route exact path="/settings" element={<Cookies />}></Route>
              <Route exact path="/projects" element={<Portfolio />}></Route>
              <Route exact path="/contact" element={<ContactMe />}></Route>
            </Routes>
          </div>
        </div>

        <div className="bottom-bar">
          <p>Copyright &copy; 2022</p>
          <p className="acc-log" onMouseEnter={(e) => updateLog}>
            <Link to="/contact">CAN WORK FROM HOME</Link>
          </p>
          <div className="bottom-icons">
            <a
              href="https://twitter.com/billykybe"
              target="_blank"
              data-tip="My Twitter"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
            <a
              href="https://www.linkedin.com/in/billy-kibet-b034b0247/"
              target="_blank"
              data-tip="Open for opportunities"
              rel="noopener noreferrer"
            >
              <LinkedIn />
            </a>
            <a
              href="https://telegram.me/billykybe"
              target="_blank"
              data-tip="Lets chat"
              rel="noopener noreferrer"
            >
              <Telegram />
            </a>
            <a
              href="https://github.com/billykybe/portfolio-lts"
              target="_blank"
              data-tip="Fork/Like Repository"
              rel="noopener noreferrer"
            >
              <Fork />
            </a>
            <Link to="/settings">
              <Settings className=" set" alt="cookies settings" />
            </Link>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
