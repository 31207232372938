/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from "react";
import "./ContactMe.css";
import emailjs from "@emailjs/browser";
import { useState } from "react";

function ContactMe() {
  const form = useRef();
  const [name, setName] = useState(localStorage.name);
  const [email, setEmail] = useState(localStorage.email);
  const [text, setText] = useState(sessionStorage.text);

  const checkFields = (e) => {
    if (name !== "" && text !== "") {
      e.target.textContent = "Checking Fields";
      // delay
      e.target.textContent = "Send Message";
    } else {
      e.target.textContent = "Confirm Fields";
    }
  };
  const sendEmail = (e) => {
    e.preventDefault();
    e.target.textContent = "Sending Message";
    //delay

    emailjs
      .sendForm(
        "service_m6cvywo",
        "template_wlip0pl",
        form.current,
        "yLxaO2_lSqA0sorZy"
      )
      .then(
        (result) => {
          console.log(result.text);

          sessionStorage.text = "";
          setText(sessionStorage.text);
          e.target.textContent = "Message Sent";
          // delay
        },
        (error) => {
          console.log(error.text);
          e.target.textContent = "Message Not Sent";
          // delay
          e.target.textContent = "Resend Message";
        }
      );
    // delay
  };
  return (
    <div className="contact-container">
      <h1 className="main-heading">Contact Me</h1>

      <div className="map-div">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8008.743028499992!2d36.95293809587002!3d-1.1620550464482609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f41401d2b6c0d%3A0x53b1266c7e173216!2sBedarin%20Hotel!5e0!3m2!1sen!2ske!4v1668265857536!5m2!1sen!2ske"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <h1 className="sub-heading">Contact Form</h1>

      <form ref={form} onSubmit={sendEmail}>
        <div className="top-form">
          <input
            type="text"
            className="input name-input"
            name="full_name"
            placeholder="Full Name"
            value={name}
            onChange={(e) => {
              localStorage.name = e.target.value;
              setName(localStorage.name);
            }}
          />
          <input
            type="text"
            className="input email-input"
            value={email}
            name="email_address"
            placeholder="Email Address"
            onChange={(e) => {
              localStorage.email = e.target.value;
              setEmail(localStorage.email);
            }}
          />
        </div>
        <div className="massage-box">
          <textarea
            name="text_msg"
            value={text}
            type="text"
            className="input-message"
            placeholder="Hello There"
            onChange={(e) => {
              sessionStorage.text = e.target.value;
              setText(sessionStorage.text);
            }}
          />
        </div>
        <button
          type="submit"
          className="send-message"
          onClick={(e) => {
            if (e.target.textContent === "Confirm Fields") {
              checkFields(e);
            } else {
              sendEmail(e);
            }
          }}
        >
          Send Message
        </button>
      </form>
    </div>
  );
}

export default ContactMe;
