import React from "react";

function Menu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "var(--color-text)" }}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" currentColor="#fff" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
}

export default Menu;
