import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AnimatedText from "react-animated-text-content";
import "./Home.css";

function Home() {
  const titles = [
    "Spring Boot Programmer",
    "Web Developer",
    "Backend Engineer",
    "Java Programmer",
    "FullStack Developer",
    "React Programmer",
    "Software Engineer",
  ];
  const [newTitle, setNewTitle] = useState("Programmer");
  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * titles.length);
    setNewTitle(titles[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 1500);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <div className="home-container">
      <div className="text-content">
        <h1>KIBET BILLY</h1>
        <p>
          Creative
          <AnimatedText
            type="words" // animate words or chars
            animation={{
              x: "200px",
              y: "-20px",
              scale: 1.1,
              ease: "ease-in-out",
            }}
            animationType="lights"
            interval={0.06}
            duration={0.8}
            tag="span"
            className="animated-paragraph"
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
          >
            {newTitle}
          </AnimatedText>
        </p>
        <Link className="learn-more" data-cursor="pointer2" to="/about">
          Learn More
        </Link>
      </div>
    </div>
  );
}

export default Home;
