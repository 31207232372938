import React from "react";
import "./Project.css";

import { projects } from "../../assets/projectsData";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import ArrowRight from "../../assets/icons/ArrowRight";

function Project(props) {
  const [id, setid] = useState(useParams().id);

  return (
    <div className="project-container">
      <h1 className="main-heading">Project Details</h1>

      <div className="project-view-img-holder">
        <img src={projects[id].img} alt="" />

        <div className="buttonsTo">
          <a
            href={projects[id].gitLink}
            target="_blank"
            rel="noopener noreferrer"
            className="learn-more"
          >
            Source Code
          </a>
          <a
            href={projects[id].liveLink}
            target="_blank"
            rel="noopener noreferrer"
            className="learn-more"
          >
            Visit Site
          </a>
        </div>
      </div>
      <h1 className="sub-heading">{projects[id].title}</h1>
      <p>{projects[id].desc}</p>
      <p className="p-tags">{projects[id].tag}</p>
      <div className="navigate-projects skills-holder">
        <p
          onClick={() => {
            if (id === 0) {
              setid(projects.length - 1);
            } else {
              if (id > 0) {
                setid(id - 1);
              } else {
                setid(projects.length - 1);
              }
            }
          }}
        >
          <ArrowLeft className="arrow" />
          Previous <span>Project</span>
        </p>
        <Link to="/projects">
          <p>
            All <span>Project</span>
          </p>
        </Link>
        <p
          onClick={() => {
            if (id === projects.length - 1) {
              setid(0);
            } else {
              if (id < projects.length - 1) {
                setid(id + 1);
              } else {
                setid(0);
              }
            }
          }}
        >
          Next <span>Project</span> <ArrowRight />
        </p>
      </div>
    </div>
  );
}

export default Project;
