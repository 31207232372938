import React from "react";

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      style={{ stroke: "var(--color-text)" }}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6" />
    </svg>
  );
}

export default ArrowRight;
