import React from "react";

function WebDev() {
  return (
    <svg
      style={{ fill: "var(--color-text)" }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 86.686"
      enable-background="new 0 0 122.88 86.686"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.995,73.994h25.399c0.064,4.396,1.872,8.325,6.783,11.429H47.22 C51.14,82.576,54.013,79.116,53.995,73.994L53.995,73.994L53.995,73.994L53.995,73.994z M41.118,30.451v53.131 c0,1.012-0.829,1.841-1.84,1.841H1.841C0.829,85.423,0,84.594,0,83.582V30.451c0-1.011,0.829-1.84,1.841-1.84h37.432 C40.289,28.611,41.118,29.439,41.118,30.451L41.118,30.451L41.118,30.451z M20.557,79.303c1.164,0,2.11,0.945,2.11,2.109 s-0.946,2.11-2.11,2.11s-2.11-0.946-2.11-2.11C18.452,80.248,19.393,79.303,20.557,79.303L20.557,79.303L20.557,79.303z M38.622,31.895v45.537H2.755V31.895H38.622L38.622,31.895L38.622,31.895z M102.54,44.215h15.942c1.213,0,2.32,0.495,3.109,1.29 c0.8,0.801,1.288,1.902,1.288,3.109v33.673c0,1.214-0.494,2.321-1.288,3.109c-0.802,0.802-1.902,1.289-3.109,1.289H102.54 c-1.214,0-2.321-0.494-3.109-1.289c-0.801-0.8-1.289-1.901-1.289-3.109V48.608c0-1.214,0.494-2.321,1.289-3.11 C100.231,44.698,101.326,44.215,102.54,44.215L102.54,44.215L102.54,44.215z M99.657,80.576h21.701V49.012H99.657V80.576 L99.657,80.576z M110.505,81.952c0.995,0,1.808,0.801,1.808,1.808c0,0.996-0.801,1.809-1.808,1.809 c-0.995,0-1.809-0.801-1.809-1.809C108.696,82.766,109.497,81.952,110.505,81.952L110.505,81.952L110.505,81.952z M17.5,0h97.411 c1.542,0,2.8,1.257,2.8,2.8v31.73h-3.976V6.268c0-1.359-1.11-2.479-2.479-2.479H21.145l0,0c-1.359,0-2.479,1.11-2.479,2.479v16.178 H14.7V2.8C14.7,1.257,15.958,0,17.5,0L17.5,0L17.5,0L17.5,0z M47.03,60.417h45.478v9.713H47.03V60.417L47.03,60.417z"
        />
      </g>
    </svg>
  );
}

export default WebDev;
