export const services = [
  {
    title: "Web Development",
    text: "I develop landing pages, admin dashboards and web applications with HTML, CSS, SCSS and React  ",
  },
  {
    title: "Bug Tracking",
    text: "Help find bugs in an existing project and fix them for your application to function as intended",
  },

  {
    title: "API Development",
    text: "I use spring boot to create apis to manage the workflow of an application and security needs",
  },

  {
    title: "Database Management",
    text: "I create database systems and APIs to manage its data from an interactive  frontend side",
  },
];
